/**
 * Prevents typing in elements with the 'readonly' class.
 */
module.exports = () => {
    const readonlyElements = document.querySelectorAll('.readonly');
    readonlyElements.forEach(element => {
        element.addEventListener('keydown', (e) => {
            e.preventDefault();
        });
    });
}
