/**
 * Sets the minimum height for the main page content to fill the screen.
 */
module.exports = () => {
    const mainPage = document.getElementById('main-page');
    if (mainPage) {
        const footerHeight = document.querySelector('footer')?.offsetHeight || 0;
        const headerHeight = document.querySelector('header')?.offsetHeight || 0;
        mainPage.style.minHeight = (window.innerHeight - footerHeight - headerHeight) + 'px';
    }
}
