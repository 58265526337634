/**
 * Handles Bootstrap Modals Logic.
 */
module.exports = () => {
    const autoloadModals = document.querySelectorAll('.modal.modal-autoload');
    autoloadModals.forEach(modal => {
        const modalInstance = bootstrap.Modal.getOrCreateInstance(modal);
        modalInstance.show();
    });

    // Allow Page Scroll on Modals without Backdrop
    const noBackdropModals = document.querySelectorAll('.modal[data-bs-backdrop="false"]');
    noBackdropModals.forEach(modal => {
        modal.addEventListener('shown.bs.modal', () => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        });
    });

    document.querySelectorAll('.modal').forEach(modal => {
        modal.addEventListener('show.bs.modal', () => {
            if (document.querySelectorAll('.modal-backdrop').length > -1) {
                document.querySelectorAll('.modal-backdrop:not(:first-of-type)').forEach(backdrop => backdrop.remove());
            }
        });

        modal.addEventListener('hidden.bs.modal', () => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        });

        modal.addEventListener('hide.bs.modal', () => {
            if (document.querySelectorAll('.modal-backdrop').length >= 1) {
                document.querySelectorAll('.modal-backdrop').forEach(backdrop => backdrop.remove());
            }
        });
    });
}
