/**
 * Handles Bootstrap collapse elements to toggle text between collapsed and expanded states.
 */
module.exports = () => {
    const collapseElements = document.querySelectorAll('[data-bs-toggle="collapse"]');
    collapseElements.forEach(collapseElement => {
        collapseElement.addEventListener('click', () => {
            const hasCollapsed = collapseElement.getAttribute('aria-expanded') === 'false';
            const collapseText = collapseElement.getAttribute('data-collapse-text') || null;
            const collapsedText = collapseElement.getAttribute('data-collapsed-text') || null;
            if (collapseText && collapsedText) {
                collapseElement.innerText = hasCollapsed ? collapsedText : collapseText;
            }
        });
    });
}
