/**
 * Adds slideDown and slideUp animations to Bootstrap dropdown menus.
 */
module.exports = () => {
    const dropdowns = document.querySelectorAll('.dropdown');
    dropdowns.forEach(dropdown => {
        dropdown.addEventListener('show.bs.dropdown', () => {
            const dropdownMenu = dropdown.querySelector('.dropdown-menu');
            dropdownMenu.style.display = 'none';
            dropdownMenu.style.display = 'block';
            dropdownMenu.style.height = 'auto';
            const height = dropdownMenu.clientHeight + 'px';
            dropdownMenu.style.height = '0';
            dropdownMenu.style.transition = 'height 0.35s ease';
            dropdownMenu.style.height = height;
            dropdownMenu.style.transition = '';
        });

        dropdown.addEventListener('hide.bs.dropdown', () => {
            const dropdownMenu = dropdown.querySelector('.dropdown-menu');
            dropdownMenu.style.transition = 'height 0.35s ease';
            dropdownMenu.style.height = '0';
            dropdownMenu.addEventListener('transitionend', () => {
                dropdownMenu.style.transition = '';
                dropdownMenu.style.display = 'none';
                dropdownMenu.style.height = '0';
            });
        });
    });
}
