(function() {
    // mobile expand dropdown
    const footerMenuDropdown = document.querySelectorAll('.footer-menu-title-mobile, .footer-secondary-menu-title-mobile');

    footerMenuDropdown.forEach(function(dropdown) {
        const footerMenu = dropdown.nextElementSibling;
        dropdown.addEventListener('click', function(e) {
            e.preventDefault();
            if (footerMenu.style.maxHeight) {
                footerMenu.style.maxHeight = null;
                dropdown.setAttribute('aria-expanded', 'false');
                footerMenu.classList.remove('expanded');
            } else {
                footerMenu.style.maxHeight = footerMenu.scrollHeight + 'px';
                dropdown.setAttribute('aria-expanded', 'true');
                footerMenu.classList.add('expanded');
            }
        });
    });
})();
