/**
 * Removes focus from dropdown buttons after they are clicked.
 */
module.exports = () => {
    const dropdownButtons = document.querySelectorAll('.dropdown > button');
    dropdownButtons.forEach(dropdownButton => {
        dropdownButton.addEventListener('click', () => {
            const dropdownMenu = dropdownButton.nextElementSibling;
            if (dropdownMenu.style.display === 'none') {
                dropdownButton.blur();
            }
        });
    });
}
