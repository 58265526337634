export function redirectPost(location, args) {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = location;

    Object.entries(args).forEach(([key, value]) => {
        const field = document.createElement('input');
        field.type = 'hidden';
        field.name = key;
        field.value = value;
        form.appendChild(field);
    });

    document.body.appendChild(form);
    form.submit();
}