window.bootstrap = require('@bootstrap/dist/js/bootstrap.bundle');
require('@js/utilities/polyfills/safari-focus-polyfill');
require('./modules/convertToBoolean');
require('./modules/displayAlertNotification');
require('./modules/removeElementById');
require('./component/voicesAlert');
require('@js/sharable/VoicesAudio');
require('@js/sharable/voicesHeader');
require('@js/sharable/voicesFooter');
require('@js/sharable/assetPath');
require('./global/redirectPost');

import initializeTooltips from '@js/sharable/bootstrapHandlers/tooltips';
import initializeCollapse from '@js/sharable/bootstrapHandlers/collapse';
import initializeModals from '@js/sharable/bootstrapHandlers/modals';
import mainPageMinHeight from '@js/sharable/mainPageMinHeight';
import unfocusDropdownButton from '@js/sharable/unfocusDropdownButton';
import addDropdownAnimations from '@js/sharable/addDropdownAnimations';
import preventTypingOnReadonlyElements from '@js/sharable/preventTypingOnReadonlyElements';

import { buttonSend, buttonReturn, buttonReturnSuccess } from '@js/sharable/buttonSend';
window.buttonSend = buttonSend;
window.buttonReturn = buttonReturn;
window.buttonReturnSuccess = buttonReturnSuccess;

/**
 * VoicesTheme Class.
 */
class VoicesTheme {
    /**
     * Initializes VoicesTheme
     */
    constructor() {
        initializeTooltips();
        initializeCollapse();
        initializeModals();
        mainPageMinHeight();
        unfocusDropdownButton();
        addDropdownAnimations();
        preventTypingOnReadonlyElements();

        this.toggleComments();
        this.preventClickOnDisabledElements();
    }

    /**
     * Toggles comment visibility when elements with the 'show-comment' class are clicked.
     */
    toggleComments() {
        const commentLinks = document.querySelectorAll('.show-comment');
        commentLinks.forEach(commentLink => {
            commentLink.addEventListener('click', (event) => {
                event.preventDefault();
                const parentElement = commentLink.parentElement.parentElement;
                const commentElement = parentElement.nextElementSibling;

                if (commentElement) {
                    commentElement.style.display = (commentElement.style.display === 'none' || commentElement.style.display === '') ? 'block' : 'none';
                }
            });
        });
    }

    /**
     * Prevents clicking on disabled elements.
     * We need to handle via JS so CSS doesn't overrite the cursor not-allowed style
     */
    preventClickOnDisabledElements() {
        const disabledElements = document.querySelectorAll('button[disabled], a[disabled]');

        disabledElements.forEach(element => {
            element.addEventListener('click', (e) => {
                e.preventDefault();
            });
        });
    }
}

new VoicesTheme();
