function assetPath(path) {
    if (!path) return '';

    // Check if the CDN is enabled by checking an environment variable
    if (global.CDN_ENABLED == 'true') {
        const protocol = 'https';
        const cdnUrl = global.CDN_URL;

        if (!cdnUrl) {
            throw new Error('CDN URL is not defined in the environment variables');
        }

        // Ensure the path does not start with a slash
        path = path.replace(/^\/+/, '');

        return `${protocol}://${cdnUrl}/${path}`;
    }

    return path;
}

window.assetPath = assetPath;