/**
 * Replacement function to use instead of remove() since IE11 does not support that function
 * @param id
 * @returns {HTMLElement}
 */
function removeElementById(id) {
    const elem = document.getElementById(id);
    if (elem && elem.parentNode) {
        elem.parentNode.removeChild(elem);
    }
}

window.removeElementById = removeElementById;