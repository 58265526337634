class VoicesAudio {
    constructor(element, players) {
        this.audioPlayer = element;
        this.playPause = this.audioPlayer.querySelector('.playPause');
        this.playpauseBtn = this.audioPlayer.querySelector('.play-pause-btn');
        this.loading = this.audioPlayer.querySelector('.loading');
        this.progress = this.audioPlayer.querySelector('.audio-progress');
        this.sliders = this.audioPlayer.querySelectorAll('.slider');
        this.player = this.audioPlayer.querySelector('audio');
        this.source = this.audioPlayer.querySelector('source');
        this.currentTime = this.audioPlayer.querySelector('.current-time');
        this.totalTime = this.audioPlayer.querySelector('.total-time');
        this.progressSlider = this.audioPlayer.querySelector('.progress-slider');
        this.progressPin = this.audioPlayer.querySelector('.progress-pin');
        this.playPath = 'M27.1214286,17.1649042 L14.55,9.225677 C13.4214286,8.5727312 12,9.41117296 12,10.7838431 L12,26.2171073 C12,27.5823576 13.4142857,28.4282192 14.55,27.7752734 L27.1214286,20.2812364 C28.2928571,19.6060311 28.2928571,17.8475294 27.1214286,17.1649042 Z';
        this.pausePath = 'M12,11 L16,11 C16.5522847,11 17,11.4477153 17,12 L17,24 C17,24.5522847 16.5522847,25 16,25 L12,25 C11.4477153,25 11,24.5522847 11,24 L11,12 C11,11.4477153 11.4477153,11 12,11 Z M20,11 L24,11 C24.5522847,11 25,11.4477153 25,12 L25,24 C25,24.5522847 24.5522847,25 24,25 L20,25 C19.4477153,25 19,24.5522847 19,24 L19,12 C19,11.4477153 19.4477153,11 20,11 Z';
        this.draggableClasses = ['pin'];
        this.currentlyDragged = null;
        this.loaded = false;
        this.autoplay = false;

        element.voicesAudio = this;
        this.bindEvents();
        this.bindPlayerEvents();
        this.source.setAttribute('data-init', 1);
        this.players = players;
        this.players.push(this);
    }

    isDraggable(el) {
        return el.classList.contains('pin');
    }

    formatTime(time) {
        let min = Math.floor(time / 60);
        let sec = Math.floor(time % 60);
        return `${min}:${sec < 10 ? '0' + sec : sec}`;
    }

    updateProgress() {
        let current = this.player.currentTime;
        let percent = (current / this.player.duration) * 100;
        this.progress.style.width = `${percent}%`;
        this.currentTime.textContent = this.formatTime(current);
    }

    inRange(event) {
        let rangeBox = this.getRangeBox(event);
        let rect = rangeBox.getBoundingClientRect();
        let direction = rangeBox.dataset.direction;
        let min, max;

        if (direction === 'horizontal') {
            min = rect.left + window.scrollX;
            max = min + rangeBox.offsetWidth;
            if (event.clientX < min || event.clientX > max) return false;
        } else {
            min = rect.top + window.scrollY;
            max = min + rangeBox.offsetHeight;
            if (event.clientY < min || event.clientY > max) return false;
        }
        return true;
    }

    getRangeBox(event) {
        let rangeBox = event.target;
        let el = this.currentlyDragged;

        if (event.type === 'click' && this.isDraggable(event.target)) {
            rangeBox = event.target.parentElement.parentElement;
        } else if (event.type === 'mousemove') {
            rangeBox = el.parentElement.parentElement;
        }
        return rangeBox;
    }

    getCoefficient(event) {
        let slider = this.getRangeBox(event);
        let rect = slider.getBoundingClientRect();
        let K = 0;

        if (slider.dataset.direction === 'horizontal') {
            const offsetX = event.clientX - rect.left; // Get the horizontal offset from the left of the slider
            const width = slider.clientWidth; // Get the width of the slider
            K = offsetX / width;
        } else if (slider.dataset.direction === 'vertical') {
            const height = slider.clientHeight;
            const offsetY = event.clientY - rect.top;
            K = 1 - offsetY / height;
        }
        return K;
    }

    makePlay() {
        this.playpauseBtn.style.display = 'block';
        this.loading.style.display = 'none';
    }

    makeLoad() {
        this.playpauseBtn.style.display = 'none';
        this.loading.style.display = 'flex';
    }

    playEvent() {
        this.checkPlayersPlaying(this.player);
        this.togglePlay();
    }

    checkPlayersPlaying(currentPlayer) {
        this.players.forEach(player => {
            if (player.player !== currentPlayer) {
                if (player.isPlaying()) {
                    player.togglePlay();
                }
            }
        });
    }

    isPlaying() {
        return this.player &&
            this.player.currentTime > 0 &&
            !this.player.paused &&
            !this.player.ended &&
            this.player.readyState > 2;
    }

    togglePlay() {
        if (this.player.paused) {
            this.playAudio();
        } else {
            this.stopAudio();
        }
    }

    playAudio() {
        this.playPause.attributes.d.value = this.pausePath;
        this.player.play();
        this.audioPlayer.dispatchEvent(new Event('voicesaudio-play'));
    }

    stopAudio() {
        this.playPause.attributes.d.value = this.playPath;
        this.player.pause();
    }

    restartAudio() {
        this.stopAudio();
        this.player.currentTime = 0;
    }

    rewind(event) {
        if (this.inRange(event)) {
            this.player.currentTime = this.player.duration * this.getCoefficient(event);
        }
    }

    setupSource() {
        if (this.source.getAttribute('data-src')) {
            this.source.setAttribute('src', this.source.getAttribute('data-src'));
            this.player.load();
        }
    }

    firstPlay() {
        this.autoplay = true;
        this.setupSource();
        this.loaded = true;
    }

    bindEvents() {
        this.playpauseBtn.addEventListener('click', () => {
            if (this.loaded) {
                this.playEvent();
            } else {
                this.makeLoad();
                this.firstPlay();
            }
        });

        this.audioPlayer?.addEventListener('hide_modal', () => this.stopAudio());

        this.progressSlider?.addEventListener('click', event => this.rewind(event));

        this.progressPin?.addEventListener('mousedown', event => {
            if (!this.isDraggable(event.target)) return;
            this.currentlyDragged = event.target;

            window.addEventListener('mousemove', event => {
                if (this.currentlyDragged && this.currentlyDragged === this.progressPin) {
                    this.rewind(event);
                }
            });

            window.addEventListener('mouseup', () => {
                this.currentlyDragged = null;
            });
        });


        // Swap back to load symbols.
        this.audioPlayer.addEventListener('load_media', () => {
            this.makeLoad();
        });

        // Stops audio playing. Useful for hiding modals.
        this.audioPlayer.addEventListener('stop_players', () => {
            this.stopAudio();
        });

        // Stop player and set time to 0. Profile tab swapping.
        this.audioPlayer.addEventListener('restart_players', () => {
            this.restartAudio();
        });

        const previewAudioModal = document.getElementById('preview_audio_modal');

        if (this.audioPlayer.id === 'player_preview') {
            previewAudioModal?.addEventListener('show.bs.modal', event => {
                let url = event.relatedTarget.dataset.url;
                let filename = event.relatedTarget.dataset.filename || '';
                if (url) {
                    this.source.setAttribute('data-src', url);
                    document.getElementById('audio-preview-file-name').textContent = filename;
                    this.autoplay = true;
                    this.setupSource();
                }
            });
        }

        previewAudioModal?.addEventListener('hide.bs.modal', () => {
            this.audioPlayer.dispatchEvent(new Event('restart_players'));
        });
    }

    bindPlayerEvents() {
        this.player?.addEventListener('timeupdate', () => this.updateProgress());

        this.player?.addEventListener('loadedmetadata', () => {
            this.makePlay();
            this.audioPlayer.classList.add('loaded-audio');
            if (this.autoplay) {
                this.autoplay = false;
                this.playEvent();
            }
        });

        this.player?.addEventListener('ended', () => {
            this.playPause.attributes.d.value = this.playPath;
        });
    }

    static initializeAll() {
        let players = [];
        document.querySelectorAll('.voices-audio-player').forEach(element => {
            const audioChildren = element.getElementsByTagName('source');
            if (audioChildren.length > 1) {
                console.error('There cannot be more than one `audio` tag per player!');
            } else {
                const sourceElement = audioChildren[0];
                if (!sourceElement || sourceElement.dataset.init === '1') return;
                new VoicesAudio(element, players);
            }
        });
    }
}

export default VoicesAudio;

document.addEventListener('DOMContentLoaded', () => {
    VoicesAudio.initializeAll();
});
