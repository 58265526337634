/**
 * Voices Alert Component
 * @param props
 * @return {VoicesAlert}
 */
function VoicesAlert(props) {

	/**
	 * Initialize Function
	 *
	 *
	 * @param params.type                    {string}  "default", "success", "error" or "danger", "info", "warning"
	 * @param params.dismissible             {bool}    Allow Alert box to be closed? true/false
	 * @param params.animationStyle          {string}  For dismissible alerts, how will the alert be displayed? "fade-in" or "slide-in"
	 * @param params.displayInline           {bool}    Append "alert-inline" class to .alert element? true/false
	 * @param params.title                   {string}  Title (optional)
	 * @param params.textContent             {string}  Description Text
	 * @param params.htmlContent             {html}    Body Content (written as custom HTML string)
	 * @param params.id                      {string}  ID Attribute for .alert element
	 * @param params.customClasses           {string}  Custom Classes for .alert element
	 * @param params.customStyles            {string}  Custom Styles for .alert element
	 * @param params.customAttributes        {object}  Custom Attributes (ie - data attributes) Example: { "data-talent-id": 129122 }
	 *
	 * @returns {VoicesAlert}
	 * @param customParams
	 */
	const initialize = function (customParams) {
		// Set Default Properties
		// Overwrite default properties with custom properties that were passed in
		const params = {
			type: "default",
			dismissible: true,
			animationStyle: "fade-in",
			displayInline: false,
			title: null,
			textContent: null,
			htmlContent: null,
			id: null,
			customClasses: null,
			customStyles: null,
			customAttributes: {}
		};
		for (const key in customParams) {
			params[key] = customParams[key];
		}

		// Get extra properties based on `type`
		let alertTypeModifierClass = "";
		let icon = "";

		switch (params.type) {
			case "success":
				alertTypeModifierClass = "alert-success";
				icon = "fas fa-check-circle";
				break;

			case "error":
			case "danger":
				alertTypeModifierClass = "alert-danger";
				icon = "fas fa-exclamation-circle";
				break;

			case "info":
				alertTypeModifierClass = "alert-info";
				icon = "fas fa-info-circle";
				break;

			case "warning":
				alertTypeModifierClass = "alert-warning";
				icon = "fas fa-exclamation-triangle";
				break;

			default:
				alertTypeModifierClass = "alert-default";
				icon = "fas fa-bookmark";
		}

		const alert = generateAlertElement({
			type: params.type,
			dismissible: params.dismissible,
			animationStyle: params.animationStyle,
			displayInline: params.displayInline,
			title: params.title,
			textContent: params.textContent,
			htmlContent: params.htmlContent,
			id: params.id,
			customClasses: params.customClasses,
			customStyles: params.customStyles,
			customAttributes: params.customAttributes,
			alertTypeModifierClass: alertTypeModifierClass,
			icon: icon
		});

		const dismissButton = alert.querySelector('button[data-bs-dismiss="alert"]');
		if (dismissButton) {
			dismissButton.addEventListener('click', onClickDismissButton);
		}

		return alert;
	};

	/**
	 * Render VoicesAlert Element
	 * @param context {object} Alert Parameters
	 * @returns {string}
	 * @private
	 */
	const generateAlertElement = function(context) {
		// ----- Alert Div
		//
		const alertDiv = document.createElement('div');
		alertDiv.setAttribute('role', 'alert');

		// ID Attribute
		if (context.id) {
			alertDiv.setAttribute('id', context.id);
		}

		// Classes
		let classesString = 'alert voices-alert ' + context.alertTypeModifierClass;
		if (context.dismissible) {
			classesString += ' alert-dismissible '+context.animationStyle;
		}
		if (context.displayInline) {
			classesString += ' alert-inline';
		}
		if (context.customClasses) {
			classesString += ' ' +context.customClasses;
		}

		alertDiv.className = classesString;

		// Custom Styles
		if (context.customStyles) {
			alertDiv.setAttribute('style', context.customStyles);
		}

		// Custom Attributes
		if (context.customAttributes) {
			for (const customAttribute in context.customAttributes) {
				alertDiv.setAttribute(customAttribute, context.customAttributes[customAttribute]);
			}
		}

		// ----- Close Button
		//
		if (context.dismissible) {
			const alertCloseButton = document.createElement('button');
			alertCloseButton.className = "close";
			alertCloseButton.setAttribute('type', 'button');
			alertCloseButton.setAttribute('data-bs-dismiss', 'alert');

			const alertCloseButtonIcon = document.createElement('i');
			alertCloseButtonIcon.className = "far fa-times";

			const alertCloseButtonScreenReaderText = document.createElement('span');
			alertCloseButtonScreenReaderText.className = "sr-only";
			alertCloseButtonScreenReaderText.innerText = "Close";

			alertCloseButton.appendChild(alertCloseButtonIcon);
			alertCloseButton.appendChild(alertCloseButtonScreenReaderText);

			alertDiv.appendChild(alertCloseButton);
		}

		// ----- Alert Icon
		//
		const alertIconBlock = document.createElement('div');
		alertIconBlock.setAttribute('aria-hidden', true);
		alertIconBlock.className = "alert-icon-block sr-hidden";

		const alertIcon = document.createElement('i');
		alertIcon.className = context.icon;
		alertIconBlock.appendChild(alertIcon);


		// Alert Body
		const alertBodyBlock = document.createElement('div');
		alertBodyBlock.className = "alert-body-block";

		// Title
		if (context.title) {
			const alertTitleDiv = document.createElement('div');
			alertTitleDiv.className = "alert-body-title";

			const alertTitleTextElement = document.createElement('strong');
			alertTitleTextElement.innerText = context.title;

			alertTitleDiv.appendChild(alertTitleTextElement);

			alertBodyBlock.appendChild(alertTitleDiv);
		}

		// Content
		const alertContentDiv = document.createElement('div');
		alertContentDiv.className = "alert-body-content";

		if (context.textContent) {
			const alertTextContentElement = document.createElement('p');
			alertTextContentElement.innerText = context.textContent;
			alertContentDiv.appendChild(alertTextContentElement);
		}
		else {
			if (context.htmlContent && typeof context.htmlContent === 'string') {
				alertContentDiv.innerHTML = context.htmlContent;
			}
			else if (context.htmlContent && typeof context.htmlContent === 'object') {
				if (context.htmlContent instanceof Node) {
					alertContentDiv.appendChild(context.htmlContent);
				} else {
					let node = document.createElement('div');
					const content = context.htmlContent;
					if (content.message) {
						node.textContent = content.message;
						alertContentDiv.appendChild(node);
					}
				}
			}
		}

		alertBodyBlock.appendChild(alertContentDiv);


		// Build Alert Div with inner components
		alertDiv.appendChild(alertIconBlock);
		alertDiv.appendChild(alertBodyBlock);

		return alertDiv;
	};

    /**
	 * Clicked Dismiss 'x' Button
     * @param event
     */
	const onClickDismissButton = function(event) {
		event.preventDefault();
        // TODO. Add custom functionality here if need be.
	};

	// Initialize Component Class
	return initialize(props);

}

window.VoicesAlert = VoicesAlert;