/* global VoicesAlert */
/* global removeElementById */

/**
 * Alert Notification - using our custom VoicesAlert js component
 *
 *
 * @param params.type                    {string}    "default", "success", "error" or "danger", "info", "warning"
 * @param params.id                      {string}    ID Attribute for .alert element
 * @param params.dismissible             {bool}      Allow Alert box to be closed? true/false
 * @param params.autoDismiss             {bool}      Automatically dismiss the alert?
 * @param params.autoDismissTimeDelay    {int}       Amount of time needed before automatically dismissing the alert
 * @param params.displayInline           {bool}      Append "alert-inline" class to .alert element? true/false
 * @param params.title                   {string}    Title (optional)
 * @param params.textContent             {string}    Description Text
 * @param params.htmlContent             {html}      Body Content (written as custom HTML)
 * @param params.container               {Node}      Node element that will contain this new alert
 * @param params.customClasses           {string}    Custom Classes for .alert element
 * @param params.customStyles            {string}    Custom Styles for .alert element
 * @param params.customAttributes        {object}    Custom Attributes (ie - data attributes) Example: {
 *     "data-talent-id": 129122 }
 *
 * @returns {VoicesAlert}
 * @param customParams
 */
function displayAlertNotification(customParams) {
    const timestamp = new Date().getTime();

    // Make sure only one alert is displayed at a time:
    // Function to remove existing alert
    const removeExistingAlerts = () => {
        const existingAlerts = Array.from(document.querySelectorAll('.voices-alert'));
        if (existingAlerts.length > 0) {
            existingAlerts.forEach(alert => alert.parentNode.removeChild(alert));
        }
    };

    // Remove existing alert before adding a new one
    removeExistingAlerts();

    // Set Default Properties
    // Overwrite default properties with custom properties that were passed in
    const params = {
        type: 'default',
        dismissible: true,
        animationDuration: 800,
        autoDismiss: true,
        autoDismissTimeDelay: 8000, // 8 seconds
        displayInline: false,
        id: 'alert-' + timestamp,
        title: null,
        textContent: null,
        htmlContent: null,
        container: document.body,
        customClasses: null,
        customStyles: null,
        customAttributes: {}
    };
    for (const key in customParams) {
        params[key] = customParams[key];
    }

    // Alert Container
    let container = params.container;

    // Alert Params
    const alertParams = {
        type: params.type,
        id: params.id,
        dismissible: params.dismissible,
        displayInline: params.displayInline,
        title: params.title,
        textContent: params.textContent,
        htmlContent: params.htmlContent,
        customClasses: params.customClasses,
        customStyles: params.customStyles,
        customAttributes: params.customAttributes
    };

    if (container === document.body) {
        alertParams.animationStyle = 'slide-in'; // custom behaviour for alert notifications... rather than "fade-in"
    }

    // Build new Alert using our custom js component
    const alert = new VoicesAlert(alertParams);

    // Detect if element was passed as a jQuery Object
    if (container && container.jquery) {
        container = container.get(0);
    }

    // Add to container
    container.appendChild(alert);

    // Additional alert handling if this is being appended to the document.body
    if (container === document.body) {
        // offsetwidth resets the element for the dom to make the transition work.
        // !important! Do not remove this without good reason.
        alert.offsetWidth;
        // Slide in animation...
        alert.classList.add('slide-in-transitioned');
    }

    if (params.dismissible && params.autoDismiss === true) {
        new Promise(function(resolve) {
            // Fade out the alert after delay time has passed
            setTimeout(function() {
                alert.className += ' out'; // fade-in/out or slide-in/out
                resolve();
            }, params.autoDismissTimeDelay);
        })
            // Then remove the element entirely after the css animation is complete
            .then(function() {
                setTimeout(function() {
                    removeElementById(alert.id);
                }, params.animationDuration);
            });
    }
}

window.displayAlertNotification = displayAlertNotification;