(function() {
    // Bootstrap Breakpoints
    const breakpoint = {
        sm: 768,
        md: 992,
        lg: 1200
    };

    // Header HTML Elements
    const header = document.getElementById('header');
    const nav = document.querySelector('.nav-main');
    const navMenuCloseTablet = document.querySelector('.nav-main-menu-close-tablet');
    const navSubmenu = document.querySelector('.nav-main-submenu');
    const navOverlay = document.querySelector('.nav-main-overlay');
    const navMenuDropdown = document.querySelectorAll('.nav-main-submenu-dropdown');
    const navMenuDropdownOverlay = document.querySelector('.nav-main-submenu-secondary-overlay');
    const navProfile = document.querySelector('.nav-profile'); // logged-in only.
    const nonLoggedInHamburgerButton = document.querySelector('.nav-main-menu-btn');
    const loggedInHamburgerButton = document.querySelector('.nav-main-submenu-link-icon-hamburger');
    const loggedInHamburgerIcon = document.querySelector('.nav-main-submenu-hamburger');
    const mobileSearchOpenButton = document.querySelector('.nav-main-submenu-search');
    const mobileSearchSearchTypeSuggestionTool = document.querySelector('.nav-search-mobile-menu-search-type-suggestion-tool');
    const recentSearchClearButtons = Array.prototype.slice.call(
        document.querySelectorAll('.nav-search-list-clear-btn')
    );

    // Mega Menu HTML Elements
    let active_class = null;
    const megaMenuTalent = document.querySelectorAll('.megaMenu-talent-items');
    const megaMenuWork = document.querySelectorAll('.megaMenu-work-items');
    const find_talent = document.getElementById('find-talent');
    const megaMenuItems = document.querySelectorAll('.megaMenu-items');
    const closeMenu = document.querySelectorAll('.backtoMenu');

    // Find Talent HTML Elements
    let talentSubMenu = [];
    let talent_marketplace, project_marketplace, client_plans;
    const subLevelFindTalentElements = document.getElementsByClassName('find-talent-sub-level')[0]?.children;
    if (subLevelFindTalentElements?.length >= 3) {
        [talent_marketplace, project_marketplace, client_plans] = subLevelFindTalentElements;
        talentSubMenu = [talent_marketplace, project_marketplace, client_plans];
    }
    // Find Work HTML Elements
    let workSubMenu = [];
    let why_voices, ways_to_earn, resources;
    const subLevelFindWorkElements = document.getElementsByClassName('find-work-sub-level')[0]?.children;
    if (subLevelFindWorkElements?.length >= 3) {
        [why_voices, ways_to_earn, resources] = subLevelFindWorkElements;
        workSubMenu = [why_voices, ways_to_earn, resources];
    }
    const find_work = document.getElementById('find-work');
    const solutionsCards = document.querySelectorAll('.solutions-cards');

    // Search HTML Elements
    const searchForm = document.querySelector('.nav-main-form');
    const searchInput = document.querySelector('.nav-main-form-input');
    const searchInputSubmitButton = document.querySelector('.nav-main-form-btn-search');
    const searchInputClearButton = document.querySelector('.nav-main-form-btn-clear');
    const searchTypeSelector = document.querySelector('.nav-main-form-selector');
    const searchLists = [
        {
            // desktop view
            element: document.querySelector('.nav-main-form-recent-searches ul'),
            includeRemoveButtons: true,
            dismissElementOnClick: true
        },
        {
            // mobile view
            element: document.querySelector('.nav-search-mobile-menu-section-recent-searches ul'),
            includeRemoveButtons: false,
            dismissElementOnClick: false
        }
    ];
    let selectedSearchType = null;
    let selectedSearchTypeName = null;
    if (searchTypeSelector) {
        const initialSearchTypeSelectionData = getSelectedSearchTypeData();
        selectedSearchType = initialSearchTypeSelectionData.slug;
        selectedSearchTypeName = initialSearchTypeSelectionData.name;
    }

    // Header States
    const isLoggedIn = header && !header.classList.contains('header-not-loggedin');

    // Ribbon SubNav HTML Elements
    const sideScrollElement = document.getElementById('nav-side-scroller');
    const sideScrollItem = document.querySelectorAll('.scroll-link');
    const leftScroll = document.querySelector('.left');
    const rightScroll = document.querySelector('.right');

    // Notifications Logic
    const notificationDropdown = document.querySelector('.nav-notifications-dropdown');

    /**
     * Get Search Items from Local Storage
     */
    function getSearchItemsFromStorage() {
        let storedVoicesSearchItems;
        if (localStorage.getItem('storedVoicesSearchItems') === null) {
            storedVoicesSearchItems = [];
        } else {
            storedVoicesSearchItems = JSON.parse(localStorage.getItem('storedVoicesSearchItems'));
        }

        // Show only 4 - in descending order
        storedVoicesSearchItems = storedVoicesSearchItems.slice(-4).reverse();

        // Return filtered results.
        return storedVoicesSearchItems;
    }

    /**
     *
     * @return {{name: string, slug: string}}
     */
    function getSelectedSearchTypeData() {
        const selectedRadioButton = searchTypeSelector.querySelector(
            'input[type="radio"]:checked'
        );

        if (!selectedRadioButton) {
            return {
                slug: 'talent',
                name: 'Talent'
            };
        }

        const associatedRadioButtonLabel = searchTypeSelector.querySelector(
            'label[for="' + selectedRadioButton.id + '"]'
        );
        if (
            !associatedRadioButtonLabel ||
            !associatedRadioButtonLabel.getAttribute('data-selection-value')
        ) {
            return {
                slug: 'talent',
                name: 'Talent'
            };
        }

        return {
            slug: selectedRadioButton.value,
            name: associatedRadioButtonLabel.getAttribute('data-selection-value')
        };
    }

    /**
     * Append Recent Search Item to Search List Container
     * @param {{element: HTMLElement, includeRemoveButtons: boolean, dismissElementOnClick: boolean}} searchList
     * @param {{query, search_type, search_type_name}} storedSearchItem       The saved search item
     * @param {string} attachChildBy    Either "append" or "prepend"
     */
    function renderSearchItemToSearchList(
        searchList,
        storedSearchItem,
        attachChildBy = 'append'
    ) {
        const li = document.createElement('li');
        li.className = 'nav-search-list-item';

        const link = document.createElement('a');
        link.className = 'nav-search-list-item-link';
        if (storedSearchItem.search_type) {
            link.href = `/${storedSearchItem.search_type === 'projects' ? 'projects' : 'talents'}/search?keywords=${storedSearchItem.query}`;
        } else {
            link.href = '/talents/search?keywords=' + storedSearchItem.query;
        }

        const recentSearchQueryTextElement = document.createElement('span');
        recentSearchQueryTextElement.className = 'search-query';
        recentSearchQueryTextElement.textContent = storedSearchItem.query;
        link.appendChild(recentSearchQueryTextElement);
        link.appendChild(document.createTextNode(' in '));
        const searchTypeTextElement = document.createElement('strong');
        searchTypeTextElement.textContent = storedSearchItem.search_type_name;
        link.appendChild(searchTypeTextElement);
        li.appendChild(link);

        if (searchList.includeRemoveButtons) {
            const removeButton = document.createElement('button');
            removeButton.className = 'nav-search-list-item-remove-btn';
            removeButton.setAttribute('role', 'button');
            removeButton.setAttribute('type', 'button');
            removeButton.setAttribute('title', 'Remove search item');
            removeButton.addEventListener('click', function() {
                removeSearchItem(storedSearchItem, li);
            });
            li.appendChild(removeButton);
        }

        if (attachChildBy === 'append') {
            searchList.element.appendChild(li);
        } else if (attachChildBy === 'prepend') {
            searchList.element.insertBefore(li, searchList.element.firstElementChild);
        }
    }


    /**
     * Render Recent Search Items onto DOM
     */
    function renderStoredSearchItemsToDOM() {
        // Stop here if not all the listed Recent Search List elements do not exist on the DOM (mobile / desktop elements)
        if (!searchLists.every(({ element }) => !!element)) {
            return;
        }

        const storedSearchItems = getSearchItemsFromStorage();

        if (storedSearchItems.length) {
            nav.classList.add('has-recent-search-items');
        }

        // Append to Recent Search Lists
        storedSearchItems.forEach(function(storedSearchItem, searchItemIndex) {
            if (searchItemIndex >= 4) return; // Only display 4 at a time.
            searchLists.forEach(function(searchList) {
                renderSearchItemToSearchList(searchList, storedSearchItem, 'append');
            });
        });
    }

    /**
     * Store Recent Search Item (Local Storage)
     * @param {string} query        The saved search query
     * @return {boolean}            Determine whether item was saved to storage. If false, this item is not unique and did not get saved.
     */
    function storeSearchItem(query) {
        let storedSearchItems;
        if (localStorage.getItem('storedVoicesSearchItems') === null) {
            storedSearchItems = [];
        } else {
            storedSearchItems = JSON.parse(localStorage.getItem('storedVoicesSearchItems'));
        }

        const isUnique =
            storedSearchItems.length === 0
            ? true
            : !storedSearchItems.some(function(storedSearchItem) {
                return (
                    storedSearchItem.query === query
                    && storedSearchItem.search_type === selectedSearchType
                );
            });

        if (!isUnique) {
            return false; // Stop here - Do not need to add the same search item.
        }

        // Add to local storage.
        storedSearchItems.push({
            query,
            search_type: selectedSearchType,
            search_type_name: selectedSearchTypeName
        });

        // Store only last 100 items
        localStorage.setItem('storedVoicesSearchItems', JSON.stringify(storedVoicesSearchItems));

        // All good! Saved to local storage.
        return true;
    }

    /**
     * Add Search Item
     * - Store into Local Storage
     * - Display new Search Item in "Recent Searches"...
     */
    function addSearchItem() {
        if (searchInput.value) {
            // Store search item in local storage
            const addedToLocalStorage = storeSearchItem(searchInput.value);
            if (!addedToLocalStorage) {
                return;
            }

            // Append to Recent Search Lists if on Search Page.
            if (window.location.pathname === '/talents/search') {
                // New Search Item
                const newSearchItem = {
                    query: searchInput.value,
                    search_type: selectedSearchType,
                    search_type_name: selectedSearchTypeName
                };

                // Update DOM Elements
                searchLists.forEach(function(searchList) {
                    // Push to start of list
                    renderSearchItemToSearchList(searchList, newSearchItem, 'prepend');

                    // Remove older item from DOM - we only want to display 4
                    while (searchList.element.children.length > 4) {
                        searchList.element.removeChild(searchList.element.children[4]);
                    }
                });

                // Update class that allows dropdown to be displayed when user focuses on input
                // eslint-disable-next-line no-use-before-define
                nav.classList.add('has-recent-search-items');
            }
        }
    }

    /**
     * Remove Search Item
     * @param {{query, search_type, search_type_name}} searchItem       The saved search item
     * @param referenceNode
     */
    function removeSearchItem(searchItem, referenceNode) {
        let storedVoicesSearchItems;
        if (localStorage.getItem('storedVoicesSearchItems') === null) {
            storedVoicesSearchItems = [];
        } else {
            storedVoicesSearchItems = JSON.parse(localStorage.getItem('storedVoicesSearchItems'));
        }

        // Remove item from localstorage
        const updatedSearchItems = storedVoicesSearchItems.filter(function(storedSearchItem) {
            return !(
                storedSearchItem.query === searchItem.query &&
                storedSearchItem.search_type === searchItem.search_type
            );
        });
        localStorage.setItem('storedVoicesSearchItems', JSON.stringify(updatedSearchItems));

        // Remove class that allows dropdown to be displayed when user hovers?
        if (!updatedSearchItems.length) {
            nav.classList.remove('has-recent-search-items');
        }

        // Get reference search item index based on reference node that was passed
        const referenceElements = Array.prototype.slice.call(searchLists[0].element.children);
        const referenceIndex = referenceElements.indexOf(referenceNode);

        // Update DOM Elements
        searchLists.forEach(function(searchList) {
            // Remove item
            searchList.element.removeChild(searchList.element.children[referenceIndex]);

            // Display another search item (which was older than the previously displayed ones)
            const countDisplayedSearchItems = searchList.element.children.length;
            const previousSearchItemIndex = ((storedVoicesSearchItems.length - 1) - (countDisplayedSearchItems + 1));
            if (previousSearchItemIndex >= 0) {
                const previousSearchItem = updatedSearchItems[previousSearchItemIndex];
                renderSearchItemToSearchList(searchList, previousSearchItem, 'append');
            }
        });
    }

    /**
     * Clear Search Items
     */
    function clearSearchItems() {
        // Remove items from localstorage
        if (localStorage.getItem('storedVoicesSearchItems')) {
            localStorage.removeItem('storedVoicesSearchItems');
        }

        // Disable dropdown from showing when user focuses on input
        nav.classList.remove('has-recent-search-items');

        // Remove items from DOM
        searchLists.forEach(function(searchList) {
            while (searchList.element.firstChild) {
                searchList.element.removeChild(searchList.element.firstChild);
            }
        });
    }

    /**
     * Update Search Type Suggestion Tool (mobile menu)
     */
    function updateMobileSearchTypeSuggestions() {
        const searchTypeSuggestionLinks = Array.prototype.slice.call(
            document.querySelectorAll('a.nav-search-mobile-menu-search-type-suggestion')
        );
        searchTypeSuggestionLinks.forEach(function(link) {
            const searchType = link.getAttribute('data-search-type') || null;
            if (searchType) {
                link.setAttribute(
                    'href',
                    `/${(searchType === 'projects') ? 'projects' : 'talents'}/search?keywords=${searchInput.value}`
                );
            } else {
                link.setAttribute(
                    'href',
                    '/talents/search?keywords=' + searchInput.value
                );
            }

            // eslint-disable-next-line no-param-reassign
            link.querySelector('.search-query').textContent = searchInput.value;

            link.addEventListener('click', function(e) {
                selectedSearchType = searchType;
                selectedSearchTypeName = e.target.innerText;
                addSearchItem();
            });
        });
    }

    /**
     * Toggle showing/hiding the Service Category Selector Dropdown
     */
    function toggleSearchTypeSelectorDropdown() {
        // Show Service Category Selector Dropdown
        if (!searchTypeSelector.classList.contains('show')) {
            searchTypeSelector.querySelectorAll('input[type="radio"]').forEach(element => {
                element.disabled = false;
            });
            searchTypeSelector.classList.add('show');

            // Prevent multiple header components from being opened
            // Logged-in Me Menu - force close for mobile menu.
            if (loggedInHamburgerButton && isLoggedIn) {
                toggleLoggedInMobileMenu('close');
            }

            // Small delay - so when clicking on the selector-button, this is not immediately triggered.
            setTimeout(function() {
                document.body.addEventListener('click', onHandleSearchTypeSelectorDropdown);
            }, 300);
        }
        // Hide Service Category Selector Dropdown
        else {
            searchTypeSelector.classList.remove('show');
            searchTypeSelector.querySelectorAll('input[type="radio"]').forEach(element => {
                element.disabled = true;
            });
            document.body.removeEventListener('click', onHandleSearchTypeSelectorDropdown);
        }
    }

    /**
     * Handle Service Category Selector Dropdown - based on user interactions
     * - Click Events - click off dropdown will close dropdown
     * - Mouse Events - mouse off will close dropdown
     * @param event
     */
    function onHandleSearchTypeSelectorDropdown(event) {
        let targetElement = event.target;
        let targetElementIsSearchTypeSelectorDropdown = false;

        if (targetElement === searchTypeSelector) {
            targetElementIsSearchTypeSelectorDropdown = true;
        }

        while (!targetElementIsSearchTypeSelectorDropdown) {
            targetElement = targetElement !== null ? targetElement.parentNode : null;
            if (targetElement === null) {
                break;
            }
            if (targetElement === searchTypeSelector) {
                targetElementIsSearchTypeSelectorDropdown = true;
            }
        }

        // Close service category dropdown since it is not targeted in this interaction.
        if (!targetElementIsSearchTypeSelectorDropdown) {
            toggleSearchTypeSelectorDropdown();
        }
    }

    /**
     * Set Lessions "NEW" Badge
     */
    function setLessonsNewBadge() {
        // Lessons click, add/remove New badge
        const lessonsBtn = document.querySelectorAll('.lessons-new');
        const badge = '<span class="status status-green">New</span>';

        // set localStorage lessons badge
        let lessonsNewBadge;
        if (localStorage.getItem('lessonsNewBadge') === null) {
            localStorage.setItem('lessonsNewBadge', 'true');
            lessonsNewBadge = true;
        } else {
            lessonsNewBadge = JSON.parse(localStorage.getItem('lessonsNewBadge'));
        }

        if (lessonsNewBadge) {
            lessonsBtn.forEach(function(btn) {
                // add new badge
                btn.insertAdjacentHTML('beforeend', badge);
                // set new bagde to false on click
                btn.addEventListener('click', function() {
                    localStorage.setItem('lessonsNewBadge', 'false');
                });
            });
        }
    }

    /**
     * Open/Close Non-LoggedIn Mobile Menu
     */
    function toggleNonLoggedInMobileMenu() {
        if (navSubmenu.style.maxHeight) {
            navSubmenu.style.maxHeight = null;
            nonLoggedInHamburgerButton.setAttribute('aria-expanded', 'false');
            nav.classList.remove('expanded');
            navOverlay.classList.remove('expanded');
            document.documentElement.classList.remove('expanded');
            document.body.classList.remove('expanded');
            navSubmenu.classList.remove('expanded');
        } else {
            navSubmenu.style.maxHeight = navSubmenu.scrollHeight + 'px';
            nonLoggedInHamburgerButton.setAttribute('aria-expanded', 'true');
            nav.classList.add('expanded');
            navOverlay.classList.add('expanded');
            document.documentElement.classList.add('expanded');
            document.body.classList.add('expanded');
            navSubmenu.classList.add('expanded');
        }
    }

    /**
     * Open/Close LoggedIn Mobile Menu (The "Me Menu")
     * @param {string|null} forcedState     Either: "open", "close" - or null - which will toggle menu naturally.
     */
    function toggleLoggedInMobileMenu(forcedState = null) {
        if (navProfile.classList.contains('expanded') || forcedState === 'close') {
            navProfile.classList.remove('expanded');
            navOverlay.classList.remove('expanded');
            loggedInHamburgerButton.setAttribute('aria-expanded', 'false');
            loggedInHamburgerIcon.classList.remove('active');
            document.documentElement.classList.remove('expanded');
            document.body.classList.remove('expanded');
        } else {
            navProfile.classList.add('expanded');
            navOverlay.classList.add('expanded');
            loggedInHamburgerButton.setAttribute('aria-expanded', 'true');
            loggedInHamburgerIcon.classList.add('active');
            document.documentElement.classList.add('expanded');
            document.body.classList.add('expanded');
            if (nav.classList.contains('expanded-search')) {
                nav.classList.remove('expanded-search');
                mobileSearchOpenButton.classList.remove('active');
            }
        }
    }


    /**
     * Toggle Mobile Search Menu (Expand/Close)
     */
    function toggleMobileSearchMenu() {
        if (!nav || !nav.classList) return;
        if (nav?.classList?.contains('expanded-search')) {
            nav.classList.remove('expanded-search');
            document.documentElement.classList.remove('expanded');
            document.body.classList.remove('expanded');
            if (mobileSearchOpenButton) {
                mobileSearchOpenButton.classList.remove('active');
            }
        } else {
            nav.classList.add('expanded-search');
            document.documentElement.classList.add('expanded');
            document.body.classList.add('expanded');
            if (mobileSearchOpenButton) {
                mobileSearchOpenButton.classList.add('active');
                mobileSearchOpenButton.blur();
            }
            setTimeout(function() {
                searchInput.focus();
            }, 100);
            if (navProfile && navProfile.classList.contains('expanded')) {
                navProfile.classList.remove('expanded');
                loggedInHamburgerButton.setAttribute('aria-expanded', 'false');
                loggedInHamburgerIcon.classList.remove('active');
            }
        }
    }


    /**
     * Mega Menu Hover status for find work and find talent
     * - Mouse Events - mouse off will hide level 2 links and mouseover will display level 2 links
     * @param megaMenu
     * @param activeClass
     * @param subMenu
     */
    function megaMenuHoverStatus(megaMenu, activeClass, subMenu) {
        megaMenu.forEach(function(menuItem) {
            menuItem.addEventListener('mouseover', function(e) {
                e.preventDefault();
                document.getElementsByClassName(activeClass)[0].classList.remove(activeClass);
                this.classList.add(activeClass);
                let subMenuId = menuItem.getAttribute('data-id');
                subMenu.forEach((key) => {
                    if (key.getAttribute('id') === subMenuId) {
                        key.classList.remove('hidden');
                    } else {
                        key.classList.add('hidden');
                    }
                });
            });
        });
    }

    /**
     * Attach Header Event Listeners
     */
    function attachEventListeners() {
        // Check if nav exists (there are some pages with different nav)
        if (nav) {
            // Search Input Events
            if (searchInput && searchInputClearButton) {
                // Focus
                searchInput.addEventListener('focus', function() {
                    searchForm?.classList.add('active');
                    if (searchInput.value.length > 0) {
                        searchInputClearButton.classList.add('show');
                    }

                    // Focus on Search Input (Not Logged-In) -- which will handle opening mobile menu based on current screensize
                    if (!isLoggedIn && window.innerWidth <= breakpoint.md - 1) {
                        toggleMobileSearchMenu();
                    }
                });

                // Blur
                searchInput.addEventListener('blur', function() {
                    searchForm.classList.remove('active');
                });

                // Search Input Typing Events
                searchInput.addEventListener('keyup', function() {
                    if (searchInput.value.length > 0) {
                        searchInputClearButton.classList.add('show');
                        mobileSearchSearchTypeSuggestionTool?.classList.add('show');
                        updateMobileSearchTypeSuggestions();
                    } else {
                        searchInputClearButton.classList.remove('show');
                        mobileSearchSearchTypeSuggestionTool?.classList.remove('show');
                    }
                });

                // Clear Current Search Input Value
                searchInputClearButton.addEventListener('click', function() {
                    searchInput.value = '';
                    searchInputClearButton.classList.remove('show');
                    mobileSearchSearchTypeSuggestionTool?.classList.remove('show');
                });
            }

            // Clear Recent Search Items Event
            recentSearchClearButtons.forEach(function(recentSearchClearButton) {
                if (!recentSearchClearButton) return;
                recentSearchClearButton.addEventListener('click', clearSearchItems);
            });

            // Submit Search Query Event
            if (searchForm) {
                searchForm.addEventListener('submit', function() {
                    // Add Search Item to storage.
                    addSearchItem();

                    // Submit Form (off Search Page)
                    if (window.location.pathname !== '/talents/search' || window.location.pathname !== '/projects/search') {
                        // Animate spinner - if not on actual search page
                        searchInputSubmitButton.querySelector('i').className = 'fa fa-circle-notch fa-spin';
                    }
                    // Submit Form (on Search Page)
                    else {
                        searchInput.blur();
                    }
                });
            }

            // Non-LoggedIn Mobile Menu Events
            if (nonLoggedInHamburgerButton && !isLoggedIn) {
                nonLoggedInHamburgerButton.addEventListener('click', toggleNonLoggedInMobileMenu);
                navMenuCloseTablet.addEventListener('click', toggleNonLoggedInMobileMenu);

                // Display SubMenu Dropdown Items (within mobile menu)
                navMenuDropdown.forEach(function(dropdown) {
                    const navMenuSecondaryContainer = dropdown.nextElementSibling;
                    dropdown.addEventListener('click', function(e) {
                        e.preventDefault();
                        if (navMenuSecondaryContainer) {
                            if (navMenuSecondaryContainer.style.maxHeight) {
                                navMenuSecondaryContainer.style.maxHeight = null;
                                dropdown.setAttribute('aria-expanded', 'false');
                                navMenuSecondaryContainer.classList.remove('expanded');
                            } else {
                                navMenuSecondaryContainer.style.maxHeight =
                                    navMenuSecondaryContainer.scrollHeight + 'px';
                                navSubmenu.style.maxHeight =
                                    navSubmenu.scrollHeight +
                                    navMenuSecondaryContainer.scrollHeight +
                                    'px';
                                nonLoggedInHamburgerButton.setAttribute('aria-expanded', 'true');
                                nav.classList.add('expanded');
                                navOverlay.classList.add('expanded');
                                document.documentElement.classList.add('expanded');
                                document.body.classList.add('expanded');
                                navSubmenu.classList.add('expanded');
                                dropdown.setAttribute('aria-expanded', 'true');
                                navMenuSecondaryContainer.classList.add('expanded');
                            }
                        }
                    });
                });
            }

            navMenuDropdownOverlay?.addEventListener('click', function() {
                navSubmenu.style.maxHeight = null;
                nonLoggedInHamburgerButton.setAttribute('aria-expanded', 'false');
                nav.classList.remove('expanded');
                navOverlay.classList.remove('expanded');
                document.documentElement.classList.remove('expanded');
                document.body.classList.remove('expanded');
                navSubmenu.classList.remove('expanded');
                navMenuDropdownOverlay.classList.remove('expanded');
                navMenuDropdown.forEach(function(dropdown) {
                    const navMenuSecondaryContainer = dropdown.nextElementSibling;
                    navMenuSecondaryContainer.style.maxHeight = null;
                    navMenuSecondaryContainer.classList.remove('expanded');
                    dropdown.setAttribute('aria-expanded', 'false');
                });
            });

            // Close nav on overlay click
            if (navOverlay) {
                navOverlay.addEventListener('click', function() {
                    nav.classList.remove('expanded');
                    navOverlay.classList.remove('expanded');
                    document.documentElement.classList.remove('expanded');
                    document.body.classList.remove('expanded');
                    navSubmenu.classList.remove('expanded');

                    if (navProfile.classList.contains('expanded')) {
                        navProfile.classList.remove('expanded');
                        loggedInHamburgerButton.setAttribute('aria-expanded', 'false');
                        loggedInHamburgerIcon.classList.remove('active');
                    }

                    if (navSubmenu.style.maxHeight) {
                        navSubmenu.style.maxHeight = null;
                        nonLoggedInHamburgerButton.setAttribute('aria-expanded', 'false');
                    }
                });
            }

            // LoggedIn Mobile Menu -- opens the "Me Menu"
            if (loggedInHamburgerButton && isLoggedIn) {
                loggedInHamburgerButton.addEventListener('click', toggleLoggedInMobileMenu);
            }

            // Open Mobile Search Menu (Search Icon)
            if (mobileSearchOpenButton) {
                mobileSearchOpenButton.addEventListener('click', toggleMobileSearchMenu);
            }

            // Show/Hide Secondary Nav On Scroll
            if (header && header.classList.contains('header-with-subnav')) {
                let lastScroll = 0;
                document.body.classList.add('scroll-up');
                window.addEventListener('scroll', function() {
                    const currentScroll = window.scrollY || window.pageYOffSet;
                    if (
                        currentScroll > lastScroll &&
                        currentScroll >= 55 &&
                        !document.body.classList.contains('scroll-down')
                    ) {
                        // down
                        document.body.classList.remove('scroll-up');
                        document.body.classList.add('scroll-down');
                    } else if (
                        currentScroll < lastScroll &&
                        document.body.classList.contains('scroll-down')
                    ) {
                        // up
                        document.body.classList.remove('scroll-down');
                        document.body.classList.add('scroll-up');
                    }
                    lastScroll = currentScroll;
                });
            }
            if (sideScrollElement) {
                let scrollItemWidth = sideScrollItem[0].offsetWidth + 40; // 40 = margin left + margin right
                let elementCutoffAdjustmentPixels = ((sideScrollElement.clientWidth / scrollItemWidth) % 1) * scrollItemWidth;
                leftScroll.addEventListener('click', function() {
                    //mobile scroll
                    if (sideScrollElement.clientWidth <= 600) {
                        scrollItemWidth = sideScrollItem[0].offsetWidth + 50;
                        elementCutoffAdjustmentPixels = ((sideScrollElement.clientWidth / scrollItemWidth) % 1) * scrollItemWidth;
                    }
                    if (sideScrollElement.scrollLeft === 0) {
                        sideScrollElement.scrollBy(sideScrollElement.scrollWidth, 0);
                    } else {
                        rightScroll.classList.remove('hidden-arrow');
                        sideScrollElement.scrollBy((sideScrollElement.clientWidth - elementCutoffAdjustmentPixels + 15) * -1, 0);
                        if ((sideScrollElement.scrollLeft) <= sideScrollElement.clientWidth) {
                            leftScroll.classList.add('hidden-arrow');
                        }
                    }
                });
                rightScroll.addEventListener('click', function() {
                    //mobile scroll
                    if (sideScrollElement.clientWidth <= 600) {
                        scrollItemWidth = sideScrollItem[0].offsetWidth + 50;
                        elementCutoffAdjustmentPixels = ((sideScrollElement.clientWidth / scrollItemWidth) % 1) * scrollItemWidth;
                    }
                    if ((sideScrollElement.scrollWidth - sideScrollElement.scrollLeft - sideScrollElement.clientWidth) <= 0.5) {
                        sideScrollElement.scrollBy(sideScrollElement.scrollLeft * -1, 0);
                    } else {
                        leftScroll.classList.remove('hidden-arrow');
                        sideScrollElement.scrollBy(sideScrollElement.clientWidth - elementCutoffAdjustmentPixels + 15, 0);
                        if ((sideScrollElement.scrollWidth - sideScrollElement.scrollLeft - sideScrollElement.clientWidth) <= sideScrollElement.clientWidth) {
                            rightScroll.classList.add('hidden-arrow');
                        }
                    }
                });
            }

            if (find_talent && find_work) {
                //find Talent mouseover
                find_talent.addEventListener('mouseover', function() {
                    active_class = 'megaMenu-talent-items-active';
                    megaMenuHoverStatus(megaMenuTalent, active_class, talentSubMenu);
                    if (document.getElementsByClassName(active_class).length === 0) {
                        //add active state to the megamenu and hide divs
                        megaMenuTalent[0].classList.add(active_class);
                        talent_marketplace.classList.remove('hidden');
                        project_marketplace.classList.add('hidden');
                        client_plans.classList.add('hidden');
                    }
                });
                //find Work mouseover
                find_work.addEventListener('mouseover', function() {
                    active_class = 'megaMenu-work-items-active';
                    megaMenuHoverStatus(megaMenuWork, active_class, workSubMenu);
                    if (document.getElementsByClassName(active_class).length === 0) {
                        //add active state to the megamenu and hide divs
                        megaMenuWork[0].classList.add(active_class);
                        why_voices.classList.remove('hidden');
                        ways_to_earn.classList.add('hidden');
                        resources.classList.add('hidden');
                    }
                });
                //solutions
                if (solutionsCards) {
                    solutionsCards.forEach(function(menuItem) {
                        menuItem.addEventListener('mouseover', function() {
                            let lastNode = menuItem.lastElementChild;
                            let arrowRight = lastNode.firstElementChild;
                            arrowRight.classList.remove('hidden');
                        });
                    });
                    solutionsCards.forEach(function(menuItem) {
                        menuItem.addEventListener('mouseout', function() {
                            let lastNode = menuItem.lastElementChild;
                            let arrowRight = lastNode.firstElementChild;
                            arrowRight.classList.add('hidden');
                        });
                    });
                }
                //small device megamenu
                megaMenuItems.forEach(function(menuItem) {
                    menuItem.addEventListener('click', function(e) {
                        e.preventDefault();
                        this.parentElement.querySelector('.menuOverlay').style = 'transform:translateX(0)';
                    });
                });
                //close level 2 menu on small screen mega menu
                closeMenu.forEach(function(item) {
                    item.addEventListener('click', function(e) {
                        e.preventDefault();
                        item.parentNode.removeAttribute('style');
                    });
                });
            }
        }

        if (notificationDropdown) {
            const notificationItemsCTA = Array.from(document.querySelectorAll('.notification-cta'));
            const notificationItemsReadBtn = Array.from(document.querySelectorAll('.notification-read-btn'));

            if (notificationItemsCTA.length > 0) {
                notificationItemsCTA.forEach(cta => {
                    cta.addEventListener('click', () => updateNotification(cta, true));
                });
            }

            if (notificationItemsReadBtn.length > 0) {
                notificationItemsReadBtn.forEach(btn => {
                    btn.addEventListener('click', (e) => {
                        e.preventDefault();
                        updateNotification(btn);
                    });
                });
            }

            function updateNotification(item, isCTAClick = false) {
                const itemData = [{
                    id: item.dataset.id,
                    source: item.dataset.source,
                    is_cta_click: true // TRUE because we don't want to trigger a toast on next page reload
                }];

                postNotificationUpdate(itemData).then(data => {
                    if (data.status === 'success') {
                        const itemID = `${item.dataset.id}_${item.dataset.source}`;
                        const notificationItem = document.querySelector(`[data-id="${itemID}"]`);

                        notificationItem.querySelector('.notification-unread-indicator')?.classList.add('hidden');
                        notificationItem.querySelector('.notification-read-btn')?.classList.add('hidden');

                        const notificationBubble = document.querySelector('.nav-main-submenu-link-icon-bubble-notifications');

                        if (notificationBubble) {
                            let notificationTotalCount = parseInt(notificationBubble.dataset.notificationCount);
                            notificationTotalCount--;

                            if (notificationTotalCount <= 99) {
                                notificationBubble.innerText = notificationTotalCount;
                            }

                            if (notificationTotalCount === 0) {
                                notificationBubble.classList.add('hidden');
                            } else {
                                notificationBubble.dataset.notificationCount = notificationTotalCount.toString();
                            }
                        }

                        if (isCTAClick && item.target !== '_blank') {
                            window.location.href = item.href;
                        } else {
                            if (window.location.pathname.includes('/member/notifications')) {
                                notificationDropdown.dispatchEvent(new Event('update_notification'));
                            }
                        }
                    }
                });
            }

        }
    }

    //find Talent mouseover
    if (find_talent) {
        find_talent.addEventListener('mouseover', function() {
            active_class = 'megaMenu-talent-items-active';
            megaMenuHoverStatus(megaMenuTalent, active_class, talentSubMenu);
            if (document.getElementsByClassName(active_class).length === 0) {
                //add active state to the megamenu and hide divs
                megaMenuTalent[0].classList.add(active_class);
                talent_marketplace.classList.remove('hidden');
                project_marketplace.classList.add('hidden');
                client_plans.classList.add('hidden');
            }
        });
    }

    //find Work mouseover
    if (find_work) {
        find_work.addEventListener('mouseover', function() {
            active_class = 'megaMenu-work-items-active';
            megaMenuHoverStatus(megaMenuWork, active_class, workSubMenu);
            if (document.getElementsByClassName(active_class).length === 0) {
                //add active state to the megamenu and hide divs
                megaMenuWork[0].classList.add(active_class);
                why_voices.classList.remove('hidden');
                ways_to_earn.classList.add('hidden');
                resources.classList.add('hidden');
            }
        });
    }
    if (solutionsCards) {
        solutionsCards.forEach(function(menuItem) {
            menuItem.addEventListener('mouseover', function() {
                let lastNode = menuItem.lastElementChild;
                let arrowRight = lastNode.firstElementChild;
                arrowRight.classList.remove('hidden');
            });
        });
        solutionsCards.forEach(function(menuItem) {
            menuItem.addEventListener('mouseout', function() {
                let lastNode = menuItem.lastElementChild;
                let arrowRight = lastNode.firstElementChild;
                arrowRight.classList.add('hidden');
            });
        });
    }

    //small device megamenu
    megaMenuItems.forEach(function(menuItem) {
        menuItem.addEventListener('click', function(e) {
            e.preventDefault();
            this.parentElement.querySelector('.menuOverlay').style = 'transform:translateX(0)';
        });
    });
    //close level 2 on small screen mega menu

    closeMenu.forEach(function(item) {
        item.addEventListener('click', function(e) {
            e.preventDefault();
            item.parentNode.removeAttribute('style');
        });
    });

    /**
     * POST Request to Update Notification Status from the Header Items
     * @param selectedNotification
     */
    async function postNotificationUpdate(selectedNotification = {}) {
        if (Object.keys(selectedNotification).length === 0) return;

        return fetch(`/member/notifications/update_notifications`, {
            method: 'POST',
            body: JSON.stringify({
                action: 'read',
                selected_notifications: JSON.stringify(selectedNotification)
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Fetch Request Failed');
                }

                return response.json();
            })
            .catch(err => {
                throw err;
            });
    }

    // Invoke Functions
    renderStoredSearchItemsToDOM();
    attachEventListeners();
    setLessonsNewBadge();
})();
